export interface State {
  data: string[];
  isLoading: boolean;
  error?: any;
}

export const initialState: State = {
  data: [],
  isLoading: false,
  error: null,
};
