import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_REQUEST = '[User Timezones] Load Request',
  LOAD_FAILURE = '[User Timezones] Load Failure',
  LOAD_SUCCESS = '[User Timezones] Load Success',

  RESET_STATE = '[User Timezones] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: string[] }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ResetSuccessAction;
