import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getData = (state: State): string[] => state.data;

export const selectUserTimezonesState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('userTimezones');

export const selectUserTimezonesError: MemoizedSelector<
  object,
  any
> = createSelector(
  selectUserTimezonesState,
  getError,
);

export const selectUserTimezonesIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  selectUserTimezonesState,
  getIsLoading,
);

export const selectUserTimezones: MemoizedSelector<
  object,
  string[]
> = createSelector(
  selectUserTimezonesState,
  getData,
);
